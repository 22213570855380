<template>
  <td
    v-if="renderCell"
    class="pt-0 pb-0"
  >
    <monitor-status-icon
      v-if="serviceStatus !== null"
      :status="serviceStatus"
    />
  </td>
</template>

<script>

import MonitorStatusIcon from '@/components/monitor/MonitorStatusIcon.vue'

export default {
  components: {
    MonitorStatusIcon,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    serviceStatus: {
      type: Number,
      required: false,
      default: () => null,
    },
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
    popoverVariant() {
      switch (this.serviceStatus) {
        case 0: return 'success'
        case 30: return 'danger'
        default: return 'danger'
      }
    },
  },
}
</script>
