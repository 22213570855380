<template>
  <td
    v-if="renderCell"
    class="pt-1 pb-1"
  >
    <div>
      <div v-if="succeededCount > 0">
        <span>{{ succeededCount }} Success</span>
        <br>
      </div>

      <div v-if="activeCount > 0">
        <span v-if="activeCount === 0">{{ activeCount }} Active</span>
        <b-badge
          v-else
          class="avamar-status-badge"
          variant="info"
        >
          {{ activeCount }} Active
        </b-badge>
        <br>
      </div>

      <div v-if="warningCount > 0">
        <span v-if="warningCount === 0">{{ warningCount }} Warning</span>
        <b-badge
          v-else
          class="avamar-status-badge"
          variant="warning"
        >
          {{ warningCount }} Warning
        </b-badge>
        <br>
      </div>

      <div v-if="failedCount > 0">
        <span v-if="failedCount === 0">{{ failedCount }} Failed</span>
        <b-badge
          v-else
          class="avamar-status-badge"
          variant="danger"
        >
          {{ failedCount }} Failed
        </b-badge>
      </div>

      <div v-if="didNotRunCount > 0">
        <span v-if="didNotRunCount === 0">{{ didNotRunCount }} did not run</span>
        <b-badge
          v-else
          class="avamar-status-badge should-run"
        >
          {{ didNotRunCount }} did not run
        </b-badge>
      </div>
    </div>
  </td>
</template>

<script>

import { BBadge } from 'bootstrap-vue'

export default {
  components: {
    BBadge,
  },
  props: {
    field: {
      type: String,
      required: true,
    },
    rowType: {
      type: String,
      required: true,
    },
    succeededCount: {
      type: Number,
      default: 0,
    },
    activeCount: {
      type: Number,
      default: 0,
    },
    warningCount: {
      type: Number,
      default: 0,
    },
    failedCount: {
      type: Number,
      default: 0,
    },
    didNotRunCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    renderCell() {
      return this.field !== undefined && this.rowType !== 'groupHeader'
    },
  },
}
</script>

<style>
    .avamar-status-badge {
        margin-top: 1px;
    }

    .avamar-status-badge.should-run {
        background-color: #a000a0;
    }
</style>
